import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Header from "../components/Header/index.js"
import BlogList from "../components/Blog/BlogList"
import SEO from "../components/seo"
import heroImage from "../images/np07.jpg"
import { withPreview } from "gatsby-source-prismic"
import { Image } from "../components/VindiciaComponents"

const Author = ({ data }) => {
  const posts = data.allPrismicBlogEntry ? data.allPrismicBlogEntry.edges : null
  const author = posts
    ? posts.map(a => a.node.data.author.document.data)[0]
    : data.prismicAuthors.data
  const postNodes = posts
    ? posts.sort(
        (a, b) =>
          new Date(b.node.data.publish_date).getTime() -
          new Date(a.node.data.publish_date).getTime(),
      )
    : null

  return (
    <Layout>
      <SEO title={author.author_name} />
      <Header image={heroImage} title={`Blog`} />
      <section className="uk-section">
        <div className="uk-container">
          <h3 className="uk-margin-remove-adjacent">Profile</h3>
          <p className="uk-heading-small">{author.author_name}</p>
          <div data-uk-grid>
            <div className="uk-width-4-5">
              <p>{author.author_bio.text}</p>
              <p>{author.role}</p>
            </div>
            {author.profile_photo ? (
              <div className="uk-width-auto">
                <Image
                  src={author.profile_photo.fixed.src}
                  srcSet={author.profile_photo.fixed.srcSet}
                  alt={author.author_name}
                />
              </div>
            ) : null}
          </div>
        </div>
      </section>
      <section className="uk-section uk-section-xsmall">
        <div className="uk-container">
          <p className="uk-h1 uk-margin-medium-bottom">Blogs by {author.author_name}</p>
          {posts ? <BlogList posts={postNodes} /> : <p>Not available in preview mode</p>}
        </div>
      </section>
    </Layout>
  )
}

export default withPreview(Author)
export const authorPosts = graphql`
  query authorPosts($uid: String) {
    allPrismicBlogEntry(filter: { data: { author: { uid: { eq: $uid } } } }) {
      edges {
        node {
          uid
          data {
            publish_date
            author {
              uid
            }
            headline {
              text
            }
            tile_image_for_blog_list__required_ {
              url
              fixed(width: 500) {
                ...GatsbyPrismicImageFixed_noBase64
              }
            }
            author {
              uid
              document {
                ... on PrismicAuthors {
                  uid
                  data {
                    author_name
                    role
                    author_bio {
                      html
                      text
                    }
                    profile_photo {
                      fixed(width: 150) {
                        ...GatsbyPrismicImageFixed_noBase64
                      }
                    }
                  }
                }
              }
            }
            categories {
              blog_tags {
                slug
                document {
                  ... on PrismicBlogCategories {
                    id
                    data {
                      label
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
